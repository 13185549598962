import { useState } from 'react';

export const useTutorial = (setIsUserMenuOpen) => {
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);

  const tutorialSteps = [
    {
      title: "Welcome to CyberPal",
      content: "CyberPal is your intelligent cybersecurity assistant. It uses advanced AI to help diagnose and solve computer issues, enhance your system's security, and provide personalized tech support.",
    },
    {
      title: "How CyberPal Works",
      content: "CyberPal works best with our Clue Collector tool. This tool gathers important system information, allowing CyberPal to provide more accurate and tailored assistance. Don't worry, your privacy and security are our top priorities!",
    },
    {
      selector: ".downloadBtn",
      title: "Download Clue Collector",
      content: "Start by downloading our Clue Collector. This tool will gather accurate information about your system, helping CyberPal provide better assistance.",
    },
    {
      selector: ".user-info",
      title: "Your Account",
      content: "Access your account information here. You'll find your unique ID, which you'll need when using Clue Collector.",
    },
    {
      selector: ".id-item",
      title: "Your Unique ID",
      content: "This is your unique ID. You'll enter this ID into the Clue Collector program after installation to link the collected data to your account.",
    },
    {
      title: "Using Clue Collector",
      content: "After installing Clue Collector, open it and enter your unique ID when prompted. This links the collected data to your CyberPal account, enabling personalized support.",
    },
    {
      selector: ".chat-input-area",
      title: "Interacting with CyberPal",
      content: "Once set up, you can start chatting with CyberPal here. Ask questions, describe issues, or request advice about your computer and cybersecurity.",
    }
  ];

  const handleTutorialStepChange = (step) => {
    setTutorialStep(step);
    const targetStep = tutorialSteps[step];
    
    // Explicitly open user menu when reaching the ID or account steps
    if (targetStep?.selector === '.id-item' || targetStep?.selector === '.user-info') {
      setIsUserMenuOpen(true);
    } else {
      setIsUserMenuOpen(false);
    }
  };

  const handleTutorialComplete = () => {
    setShowTutorial(false);
    setTutorialStep(0);
    setIsUserMenuOpen(false);
  };

  return {
    showTutorial,
    setShowTutorial,
    tutorialStep,
    tutorialSteps,
    handleTutorialStepChange,
    handleTutorialComplete
  };
};