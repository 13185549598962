import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

//const apiBaseUrl = "https://CyberPalMain-486723335.eu-central-1.elb.amazonaws.com";
const apiBaseUrl = "https://api.cyberpalcbg.com"
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <App apiBaseUrl={apiBaseUrl} />
);