import { useState, useRef, useEffect } from "react";
import { Amplify } from "aws-amplify";
import axios from "axios";
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { Authenticator, withAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { ChevronDown, ChevronUp, Bell } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { ClipLoader } from "react-spinners";
import cbgLogo from "./assets/cbg.png";
import addBtn from "./assets/add-30.png";
import msgIcon from "./assets/message.svg";
import sendBtn from "./assets/send.svg";
import userIcon from "./assets/user-icon.png";
import gptImgLogo from "./assets/Untitled.png";
import fileIcon from "./assets/attachFile.png";
import fileImg from "./assets/fileImg.png";
import techImg from "./assets/tech-logo.png";
import collapseIcon from "./assets/collapse-icon.png";
import expandIcon from "./assets/expand-icon.png";
import DefaultChatOptions from "./components/DefaultChatOptions";
import UserMenu from "./components/UserMenu";
import DataModalProf from "./DataModalProf";
import { ArrowRight, HelpCircle } from "lucide-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import "./custom-auth.css";
import KJUR from "jsrsasign";
import FeedbackButtons from "./components/FeedbackButtons";
import TutorialBubble from "./components/TutorialBubble";
import { useAuth } from "./hooks/useAuth";
import { useSession } from "./hooks/useSession";
import { useChat } from "./hooks/useChat";
import { useTutorial } from "./hooks/useTutorial";
import { useRecommendations } from "./hooks/useRecommendations";
import ChatMessage from "./components/Chat/ChatMessage";
import ChatInput from "./components/Chat/ChatInput";
import LoadingIndicator from "./components/Chat/LoadingIndicator";
import Sidebar from "./components/Chat/Sidebar";

//fonts
import "@fontsource/roboto";
import "@fontsource/open-sans";
import "@fontsource/montserrat";
import "@fontsource/inter";

Amplify.configure(awsExports);

function App({ apiBaseUrl }) {
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [currentFont, setCurrentFont] = useState("Arial, sans-serif");
  const { userName, userEmail, handleLogout } = useAuth();

  const { userId, sessionToken, initiateSession, sessionInitiated } =
    useSession(apiBaseUrl);
  const {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    showDefaultOptions,
    setShowDefaultOptions,
    diagnosis,
    actionSteps,
    showNextButton,
    currentStepIndex,
    files,
    setFiles,
    currentLoadingPrompt,
    techLevels,
    setTechLevels,
    handleSendMsg,
    handleDefaultOption,
    sendMsgToOpenAI,
    msgEnd,
  } = useChat(sessionToken, apiBaseUrl, initiateSession, userId);

  const {
    showTutorial,
    setShowTutorial,
    tutorialStep,
    tutorialSteps,
    handleTutorialStepChange,
    handleTutorialComplete,
  } = useTutorial(setIsUserMenuOpen);

  const {
    recommendation,
    isConnected,
    fetchRecommendation,
    checkConnectivity,
  } = useRecommendations(sessionToken, apiBaseUrl, isSendingMessage); //isloading?

  const handleFontChange = (newFont) => {
    setCurrentFont(newFont);
    document.body.style.fontFamily = newFont;
  };

  const [currentStepIndices, setCurrentStepIndices] = useState({});
  const handleNextStep = (messageIndex) => {
    setCurrentStepIndices((prevIndices) => ({
      ...prevIndices,
      [messageIndex]: (prevIndices[messageIndex] || 0) + 1,
    }));
  };

  const handleFeedback = async (messageId, isPositive) => {
    if (!sessionToken) {
      console.error("No session token available");
      return;
    }

    try {
      await axios.post(
        `${apiBaseUrl}/submit_feedback`,
        { message_id: messageId, is_positive: isPositive },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log("Feedback submitted successfully");
    } catch (error) {
      console.error("Failed to submit feedback:", error);
      if (error.response && error.response.status === 401) {
        await initiateSession(userId);
      }
    }
  };

  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(true);
  const [associatedId, setAssociatedId] = useState(null);
  const [associatedCid, setAssociatedCid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chatMessagesRef = useRef(null);
  const checkAssociatedCid = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiBaseUrl}/get_associated_cid`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
      });
      const ccId = response.data.message.cc_id;
      setAssociatedCid(ccId);
      setError(null);
    } catch (error) {
      console.error("Failed to check associated CID:", error);
      setError("Failed to check associated CID");
      setAssociatedCid(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sessionToken) {
      checkAssociatedCid();
    }
  }, [sessionToken, apiBaseUrl]);


  /**
   * Sends a message to the OpenAI API and returns the response.
   * @param {string} msg - The message to send to the OpenAI API.
   * @returns {Promise<string>} - The response from the OpenAI API.
   */


  useEffect(() => {
    msgEnd.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleEnter = async (e) => {
    if (e.key === "Enter") await handleSendMsg();
  };

  const downloadScript = async () => {
    if (!sessionToken) {
      console.error("No user ID or session token available");
      return;
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/download_script`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      const { message, download_url } = response.data;

      // Extract the associated ID (cc_id) from the message
      if (message && message.cc_id) {
        setAssociatedId(message.cc_id);
      }

      // Initiate the download
      window.location.href = download_url;

      // Hide the download button and show the associated ID
      setIsDownloadButtonVisible(false);
    } catch (error) {
      console.error("Failed to download ClueCollector:", error);
      if (error.response && error.response.status === 401) {
        // Token might be expired, initiate a new session
        await initiateSession(userId);
        // You might want to retry the download after re-initiating the session
      }
    }
  };

  const cancelDownload = async () => {
    if (!sessionToken) {
      console.error("No session token available");
      return;
    }

    try {
      const response = await axios.post(
        `${apiBaseUrl}/sys_info_canceled`,
        {}, // empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log("Cancel request sent successfully");
      setIsDownloadButtonVisible(false); // Hide the button after clicking
    } catch (error) {
      console.error("Error sending cancel request:", error);
      if (error.response && error.response.status === 401) {
        // Token might be expired, initiate a new session
        await initiateSession(userId);
        // You might want to retry the cancel request after re-initiating the session
      }
    }
  };

  useEffect(() => {
    const clearConversationHistory = async () => {
      if (!sessionToken || !sessionInitiated.current) return;

      try {
        const response = await axios.post(
          `${apiBaseUrl}/clear_history_gen`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        console.log("Conversation history cleared", response.data);
      } catch (error) {
        console.error("Failed to clear conversation history:", error);
        if (error.response && error.response.status === 401) {
          console.error(
            "Authentication failed. Attempting to refresh session..."
          );
          sessionInitiated.current = false;
          await initiateSession(userId);
        }
      }
    };

    if (sessionToken && sessionInitiated.current) {
      clearConversationHistory();
    }
  }, [sessionToken, userId, apiBaseUrl]);

  const CustomHeader = () => (
    <div>
      <img
        src={cbgLogo}
        alt="Security Advisor"
        className="security-advisor-image"
      />
    </div>
  );


  // Sidebar collapse
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const theme = createTheme({
    name: "custom-theme",
    tokens: {
      colors: {
        background: { primary: { value: "#2a2727" } },
        font: { primary: { value: "#ffffff" } },
      },
      components: {
        button: {
          fontWeight: { value: "600" },
          borderRadius: { value: "4px" },
        },
      },
      fonts: {
        default: {
          variable: { value: currentFont },
        },
      },
    },
  });

  // Handles sending a query from quick query buttons
  const handleQuery = async (e) => {
    const text = e.target.value;
    setInput("");
    setMessages([...messages, { text: text, isBot: false }]);
    const res = await sendMsgToOpenAI(text);
    setMessages([
      ...messages,
      { text: text, isBot: false },
      { text: res, isBot: true },
    ]);
  };

  /* clue collector */

  const launchClueCollector = () => {
    const protocolUrl = "cluecollector://launch";

    // Create hidden iframe to handle protocol without changing the current page
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    try {
      iframe.contentWindow.location.href = protocolUrl;

      // Cleanup iframe after a delay
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);

      // Check if app opened after a short delay
      setTimeout(() => {
        if (document.hasFocus()) {
          // If we still have focus, app probably didn't open
          alert(
            "ClueCollector doesn't seem to be installed. Please install it first."
          );
        }
      }, 500);
    } catch (e) {
      alert(
        "ClueCollector doesn't seem to be installed. Please install it first."
      );
    }
  };

  // Handles file upload
  function handleUpload(e) {
    const file = e.target.files;
    const file_arr = [...files];
    if (file.length > 0) {
      const fd = new FormData();
      for (let i = 0; i < file.length; i++) {
        fd.append(`file${i + 1}`, file[i]);
        file_arr.push(file[i].name);
      }
      axios
        .post(`${apiBaseUrl}/upload`, fd)
        .then((res) => {
          console.log(res);
          setUploadError(""); // Reset error message on successful upload
          setIsErrorModalOpen(false);
          setFiles(file_arr);
        })
        .catch((err) => {
          console.error(err);
          setUploadError(
            "An error occurred during file upload. Please try again."
          );
          setIsErrorModalOpen(true);
        });
    }
  }

  /* cluecollector */

  /**
   * ErrorModal component displays an error message in a modal dialog.
   *
   * @component
   * @param {Object} props - The component props.
   * @param {boolean} props.isOpen - Determines whether the modal is open or not.
   * @param {function} props.onClose - The function to be called when the modal is closed.
   * @param {string} props.errorMessage - The error message to be displayed.
   * @returns {JSX.Element|null} The ErrorModal component.
   */
  function ErrorModal({ isOpen, onClose, errorMessage }) {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(28, 30, 58, 1)",
            padding: "20px",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.5rem",
              paddingBottom: "10px",
            }}
          >
            {errorMessage}
          </p>{" "}
          {/* Adjusted text color for better readability */}
          <button
            onClick={onClose}
            style={{
              backgroundColor: "#4CAF50", // A green color for the button
              color: "white", // White text color for the button
              padding: "10px 20px", // Adjusted padding for better look
              margin: "10px 0", // Added some margin to top for spacing
              border: "none", // Remove the default border
              borderRadius: "4px", // Rounded corners for the button
              cursor: "pointer", // Cursor to pointer to indicate it's clickable
              transition: "background-color 0.3s", // Smooth transition for hover effect
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#45a049")
            } // Darker green on hover
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "#4CAF50")
            } // Original green when not hovered
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Authenticator components={{ Header: CustomHeader }}>
        {({ signOut, user }) => (
          <div
            className={`App ${isDownloadButtonVisible ? "dimmed" : ""}`}
            style={{ fontFamily: currentFont }}
          >
            <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
              <button className="sidebar-toggle" onClick={toggleSidebar}>
                <img
                  src={isSidebarCollapsed ? expandIcon : collapseIcon}
                  alt={isSidebarCollapsed ? "Expand" : "Collapse"}
                  width="20"
                  height="20"
                />
              </button>
              <div className="upperSide">
                <div className="upperSideTop">
                  <span className="cyberpal-title">CyberPal</span>
                </div>
                <button
                  className="midBtn"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <img className="addBtn" alt="new chat" src={addBtn} />
                  New Chat
                </button>
              </div>
              <div className="downloadSection">
                <button onClick={downloadScript} className="downloadBtn">
                  Download Installer
                </button>
                <button
                  onClick={launchClueCollector}
                  className="downloadBtn"
                  style={{ marginTop: "10px", backgroundColor: "#4CAF50" }}
                >
                  Launch ClueCollector
                </button>
              </div>
              <div className="lowerSide">
                <UserMenu
                  isOpen={isUserMenuOpen}
                  setIsOpen={setIsUserMenuOpen}
                  onClose={() => setIsUserMenuOpen(false)}
                  userEmail={userEmail}
                  onLogout={handleLogout}
                  onRejectClueCollector={cancelDownload}
                  associatedId={associatedId}
                  isConnected={isConnected}
                  onFontChange={handleFontChange}
                  checkAssociatedCid={associatedCid}
                  hasRecommendation={recommendation !== null}
                  recommendationContent={recommendation}
                />
              </div>
            </div>
            <div className="main">
              <div className="chat-container">
                <div className="chat-messages" ref={chatMessagesRef}>
                  {!showTutorial && (
                    <button
                      className="tutorial-icon"
                      onClick={() => setShowTutorial(true)}
                      aria-label="Start Tutorial"
                    >
                      <HelpCircle size={24} />
                    </button>
                  )}

                  {showTutorial && (
                    <TutorialBubble
                      steps={tutorialSteps}
                      onComplete={handleTutorialComplete}
                      onStepChange={handleTutorialStepChange}
                    />
                  )}

                  {showDefaultOptions && (
                    <DefaultChatOptions onOptionSelect={handleDefaultOption} />
                  )}
                  {messages.map((message, index) => (
                    <ChatMessage
                      key={index}
                      message={message}
                      index={index}
                      currentStepIndices={currentStepIndices}
                      onHover={setHoveredMessageIndex}
                      onLeaveHover={() => setHoveredMessageIndex(null)}
                      onNextStep={handleNextStep}
                      onFeedback={handleFeedback}
                    />
                  ))}
                  <LoadingIndicator
                    isLoading={isLoading}
                    loadingPrompt={currentLoadingPrompt}
                  />
                  <div ref={msgEnd}></div>
                </div>
                <ChatInput
                  input={input}
                  setInput={setInput}
                  isLoading={isLoading}
                  onSend={handleSendMsg}
                  onUpload={handleUpload}
                  onEnter={handleEnter}
                />
              </div>
            </div>
            <ErrorModal
              isOpen={isErrorModalOpen}
              onClose={() => setIsErrorModalOpen(false)}
              errorMessage={uploadError}
            />
          </div>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
