import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import KJUR from 'jsrsasign';
import { getCurrentUser } from '@aws-amplify/auth';

export const useSession = (apiBaseUrl) => {
  const [userId, setUserId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const sessionInitiated = useRef(false);
  const SECRET_KEY = "TV1l0MyTZDup463AO6rIvKhosVldS9i5";

  const initiateSession = async (cognitoUserId) => {
    if (sessionInitiated.current) return; // Prevent multiple initiations

    try {
      // Create a JWT payload
      const payload = {
        userId: cognitoUserId,
        exp: Math.floor(Date.now() / 1000) + 60 * 60, // 1 hour from now
      };

      // Sign the JWT
      const header = { alg: "HS256", typ: "JWT" };
      const sHeader = JSON.stringify(header);
      const sPayload = JSON.stringify(payload);
      const encryptedUserId = KJUR.jws.JWS.sign(
        "HS256",
        sHeader,
        sPayload,
        SECRET_KEY
      );

      const response = await axios.post(
        `${apiBaseUrl}/initiate_session`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "Encrypted-UUID": encryptedUserId,
          },
        }
      );

      const { sessionToken } = response.data;
      setSessionToken(sessionToken);
      localStorage.setItem("sessionToken", sessionToken);
      sessionInitiated.current = true;
      console.log("Session initiated successfully");
    } catch (error) {
      console.error("Failed to initiate session:", error);
    }
  };

  useEffect(() => {
    const fetchCurrentUserAndInitiateSession = async () => {
      if (!userId) {
        try {
          const currentUser = await getCurrentUser();
          const fetchedUserId = currentUser.userId;
          setUserId(fetchedUserId);

          const storedToken = localStorage.getItem("sessionToken");
          if (storedToken) {
            setSessionToken(storedToken);
            sessionInitiated.current = true;
          } else {
            await initiateSession(fetchedUserId);
          }
        } catch (error) {
          console.error("Error fetching current user: ", error);
        }
      }
    };

    fetchCurrentUserAndInitiateSession();
  }, [userId, apiBaseUrl]);

  return {
    userId,
    sessionToken,
    initiateSession,
    sessionInitiated,
  };
};