import { useState, useEffect } from 'react';
import axios from 'axios';

export const useRecommendations = (sessionToken, apiBaseUrl, isSendingMessage) => {
  const [recommendation, setRecommendation] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const fetchRecommendation = async () => {
    if (isSendingMessage || !sessionToken) {
      console.log("Message being sent, skipping rec check");
      return;
    }

    try {
      const response = await axios.get(
        `${apiBaseUrl}/get_recommendation`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = response.data;
      if (data.rec === true && data.rec_content) {
        setRecommendation(data.rec_content);
      } else {
        setRecommendation(null);
      }
    } catch (error) {
      console.error('Failed to fetch recommendation:', error);
      setRecommendation(null);
      if (error.response && error.response.status === 401) {
        // You'll need to handle session reinitialization here if needed
        console.error('Session expired during recommendation fetch');
      }
    }
  };

  const checkConnectivity = async () => {
    if (isSendingMessage) {
      console.log("Message being sent, skipping connectivity check");
      return;
    }

    try {
      const response = await axios.get(
        `${apiBaseUrl}/check_agent_connectivity`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsConnected(response.data.connected);
    } catch (error) {
      console.error('Failed to check agent connectivity:', error);
      setIsConnected(false);
    }
  };

  useEffect(() => {
    if (sessionToken) {
      const pollInterval = setInterval(() => {
        if (!isSendingMessage) {
          checkConnectivity();
          fetchRecommendation();
        }
      }, 10000); // Poll every 10 seconds when not sending a message

      return () => clearInterval(pollInterval);
    }
  }, [sessionToken, isSendingMessage]);

  return {
    recommendation,
    isConnected,
    fetchRecommendation,
    checkConnectivity
  };
};